import React, { useMemo } from 'react';
import './sceneFlycamOutdoorHotspot.scss';
import './mediaHotspotLine.scss';
import { setDateLight } from 'store/actions';

const SceneFlycamOutdoorHotspot = ({ onClick, scene, dispatch, onSelect }) => {
  const titles = useMemo(() => {
    if (scene.title) {
      const t = (scene.title || '').toLowerCase();
      if (t.toLowerCase().indexOf('sân tennis') === 0) {
        return t
          .split('&')
          .map((s) => s.replace('- ', '').replace('', '').trim());
      }
      return [t].map((s) => s.replace('- ', '').replace('', '').trim());
    }
    return [''];
  }, [scene]);

  const aerial = ['aerial-daylight', 'aerial-night'];

  const handleOnClick = () => {
    if (aerial.includes(scene.id)) {
      dispatch(setDateLight(scene.id === aerial[0]));
      setTimeout(() => {
        onSelect('welcome', scene.id, true);
      }, 0);
      return;
    }
    onClick();
  };

  return (
    <div className="khp-outdoor-hotspot" onClick={handleOnClick}>
      <div className="khp-outdoor-hotspot__pulse">&nbsp;</div>
      <div className="khp-outdoor-hotspot__title">
        {titles.map((title) => (
          <p key={title} className="khp-outdoor-hotspot__title-text">
            {title}
          </p>
        ))}
        <div className="khp-outdoor-hotspot__thumb">
          <img src={scene.previewImgUrl} alt={titles[0]} />
        </div>
      </div>
    </div>
  );
};

export default SceneFlycamOutdoorHotspot;
