import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOppositeScene, isDayOrNight } from './dayNight.const';
import DayTimeSwitch from '.';
import { setDateLight } from 'store/actions';

export default function CheckingSwitchDayTime({ currentPano, onSelect }) {
  const dateLight = useSelector((state) => state.dateLight);
  const dispatch = useDispatch();

  const toggleDateLight = useCallback(() => {
    const opp = getOppositeScene(currentPano?.id);
    if (opp) {
      dispatch(setDateLight(!dateLight));
      onSelect(currentPano.groupId, opp, true);
    }
  }, [dateLight, onSelect, dispatch, currentPano]);

  const isVisibleDayNightControl = useMemo(
    () => isDayOrNight(currentPano?.id),
    [currentPano]
  );

  if (!isVisibleDayNightControl) return null;

  return (
    <div className="action">
      {isVisibleDayNightControl && (
        <DayTimeSwitch daylight={dateLight} setDaylight={toggleDateLight} />
      )}
    </div>
  );
}
