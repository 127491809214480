import React, { useEffect, useMemo, useRef, useState } from 'react';
// import compassImg from './laban-400px.png';
import rCompassImg from './compass-ruler.png';
// import compassArrowImg from './arrow-laban.png';
import RotationHandler from './RotationHandler';
import RulerArrow from './ruler-arrow.svg';

import './style.scss';

const radiansToDegrees = (radians) => {
  return Math.round(radians * (180 / Math.PI));
};

const DEFAULT_RULER_PARAM = 0.9013888888888889;

const RullerCompass = ({ deg }) => {
  const compassRef = useRef(null);

  const RulerTranslation = useMemo(() => {
    return -deg * DEFAULT_RULER_PARAM;
  }, [deg]);

  useEffect(() => {
    compassRef.current.style.transform = `translateX(${
      RulerTranslation || 0
    }px)`;
  }, [RulerTranslation]);

  return (
    <div className="Rcompass-container">
      <img
        className="Rcompass-container--ruler-arrow"
        src={RulerArrow}
        alt="ruller-arrow"
      />
      <div className="Rcompass-container--wrapper" ref={compassRef}>
        <div
          className="Rcompass-container--img"
          style={{ backgroundImage: `url(${rCompassImg})` }}
        ></div>
      </div>
    </div>
  );
};

const COMPASS_DEGREE_DATA = {
  // 'khu-vuc-la-ban-1': -40,
  // 'khu-vuc-la-ban-2': 140,
  'aerial-daylight': -60,
  'aerial-night': 140,
  'amenitiesl-mall-daylight': 35,
  'amenitiesl-mall-night': 35,
  'amenitiesl-hongkong-daylight': 40,
  'amenitiesl-hongkong-night': 40,
  'amenitiesl-park-daylight': -10,
  'amenitiesl-park-night': -10,
  'vuon-hai-loc-daylight': -130,
  'ho-boi-night': -190,
};

const CompassContainer = ({
  currentPano,
  cameraParams,
  sceneRotation,
  ...props
}) => {
  const [rotationY, setRotationY] = useState(0);
  const isVisible = useMemo(() => {
    if (currentPano?.id && COMPASS_DEGREE_DATA[currentPano?.id] !== undefined) {
      return true;
    }
    return false;
  }, [currentPano]);

  const defaultDegree = useMemo(() => {
    if (currentPano?.id && COMPASS_DEGREE_DATA[currentPano?.id]) {
      return COMPASS_DEGREE_DATA[currentPano?.id];
    }
    return 0;
  }, [currentPano]);

  const compassDeg =
    useMemo(() => radiansToDegrees(rotationY), [rotationY]) || 0;

  if (!isVisible) return null;

  return (
    <>
      {currentPano && (
        <RotationHandler
          cameraParams={cameraParams}
          sceneRotation={sceneRotation}
          setRotationY={setRotationY}
          currentPano={currentPano}
        />
      )}
      {/* <Compass {...props} deg={compassDeg + defaultDegree} /> */}
      <RullerCompass {...props} deg={compassDeg + defaultDegree} />
    </>
  );
};

export default CompassContainer;
