import {
  // AudioIcon,
  // AutoPlayIcon,
  AutoRotateIcon,
  // ContactFormIcon,
  ExpandIcon,
  FloorMapIcon,
  FullScreenIcon,
  // HelpIcon,
  MapFloorPlanIcon,
  // MapIcon,
  // ShareIcon,
} from 'components/icons';
import { useMediaQuery } from 'react-responsive';
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
// import { setAutoPlay } from 'store/actions';
import { canFullscreen, toggleFullScreen } from 'utils';
import { useNavigatedRoutes } from 'common/hooks';
// import configs from 'configs';
import { limitLetters } from 'utils/limitLetters';
import FloorButtons from './FloorButtons';
import { setDateLight } from 'store/actions';

const MenuActions = (props) => {
  const {
    menuPosition,
    currentPano,
    toggleRotate,
    shouldRotate,
    featuredMedia,
    color,
    isShowMapFloorPlan,
    floorMaps,
    onSelect,
    scenes,
    openMenu,
    isAquaStyle,
    setIsActive,
    isRenderFloorButtons,
  } = props;

  const [handleUpdateRoute] = useNavigatedRoutes();
  const [fullScreen, setFullScreen] = useState(false);

  // const autoPlayMode = useSelector((state) => state.autoPlayMode);
  // const [isAutoPlay, setIsAutoPlay] = useState(autoPlayMode);
  const [isActiveMap, setIsActiveMap] = useState(window.innerWidth > 767);

  const mobile = useRef(window.innerWidth <= 767);
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' });

  const [openDropDown, setOpenDropDown] = useState(false);
  const [mapShowed, setMapShowed] = useState();

  useEffect(() => {
    setOpenDropDown(false);
  }, [currentPano]);

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('khp-menu-open');
    } else {
      document.body.classList.remove('khp-menu-open');
    }
  }, [openMenu]);

  useEffect(() => {
    setOpenDropDown(false);
  }, [currentPano]);

  // const shouldShowMapIcon = useMemo(
  //   () =>
  //     tour.mapCoordinates.length > 0 &&
  //     !(tour.mapCoordinates[0] === 0 && tour.mapCoordinates[1] === 0),
  //   [tour]
  // );

  // const toggleHelpButton = () => {
  //   showLanding();
  // };

  const handleFullScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const dispatch = useDispatch();

  // const switchMode = useCallback(() => {
  //   setIsAutoPlay(!isAutoPlay);
  //   dispatch(setAutoPlay(!isAutoPlay));
  // }, [dispatch, isAutoPlay]);

  const handleSelectMap = (item) => {
    setMapShowed(item);
  };

  useEffect(() => {
    if (floorMaps.length > 0 && currentPano) {
      const getLinkedFloorPlanFromOtherScene = (floorMaps, pano) => {
        return floorMaps.find((item) =>
          item.objects.length
            ? item.objects.some((obj) => obj.id === pano._id)
            : item
        );
      };

      const linkedFloorPlan = getLinkedFloorPlanFromOtherScene(
        floorMaps,
        currentPano
      );
      if (linkedFloorPlan) {
        setMapShowed(linkedFloorPlan);
      } else {
        setMapShowed(floorMaps[0]);
      }
    }
  }, [currentPano, floorMaps]);

  const handleMapClick = useCallback((event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    // eslint-disable-next-line
    const x = (event.clientX - rect.left) / rect.width;
    // eslint-disable-next-line
    const y = (event.clientY - rect.top) / rect.height;

    console.log(`Clicked position: (${x.toFixed(4)}, ${y.toFixed(4)})`);
  }, []);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (mobile.current) setIsActiveMap(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  const overviewMapStyle = useMemo(
    () =>
      floorMaps.length &&
      floorMaps[0]?.image.url.includes(
        '/assets/project-images/media/overview-map.jpg'
      ),
    [floorMaps]
  );

  const aerial = [
    'aerial-daylight',
    'aerial-night',
    'amenitiesl-mall-night',
    'amenitiesl-mall-daylight',
    'amenitiesl-park-night',
    'amenitiesl-park-daylight',
    'amenitiesl-hongkong-night',
    'amenitiesl-hongkong-daylight',
  ];

  const nightScene = [
    'amenitiesl-park-night',
    'ho-boi-night',
    'amenitiesl-hongkong-night',
    'amenitiesl-mall-night',
    'aerial-night',
  ];

  return (
    <>
      {isActiveMap && <div className="larita-bgcover"></div>}
      <div id="actions-icons">
        {featuredMedia.map && (
          <div
            className={`icon`}
            onClick={() => handleUpdateRoute(featuredMedia.map.id)}
          >
            <FloorMapIcon />
          </div>
        )}

        {isShowMapFloorPlan && (
          <>
            <div
              className={`icon position-relative ${
                isAquaStyle ? 'icon-aquaStyle' : ''
              }`}
              onClick={() => setIsActiveMap(!isActiveMap)}
            >
              <MapFloorPlanIcon active={isActiveMap} />
            </div>
            <div
              className={`mapfloorplan_container ${
                isActiveMap ? 'open' : ''
              }  ${
                isActiveMap ? 'mapfloorplan_container-aacorp open-aacorp' : ''
              }`}
              ref={containerRef} // Gán tham chiếu
              style={{
                width: !isPhone ? (overviewMapStyle ? 360 : 450) : null,
              }}
            >
              {isRenderFloorButtons && <FloorButtons {...props} />}

              {mapShowed && (
                <>
                  {floorMaps?.length > 1 && (
                    <div className="map_header">
                      <div
                        className="map_dropdown"
                        onClick={() => setOpenDropDown(!openDropDown)}
                      >
                        <span>{limitLetters(mapShowed.image.name, 15)}</span>
                        <i className="map_arrow down"></i>
                        {openDropDown && (
                          <div
                            className={`map_dropdown_options ${
                              openDropDown ? 'show' : ''
                            }`}
                          >
                            <ul className="dropdown_items">
                              {floorMaps?.map((item, index) => (
                                <li
                                  onClick={() => handleSelectMap(item)}
                                  key={index}
                                >
                                  {limitLetters(item.image.name, 10)}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      <div className={`icon`}>
                        <ExpandIcon />
                      </div>
                    </div>
                  )}
                  <div className="map_image" onClick={handleMapClick}>
                    <img src={mapShowed.image.url} alt={mapShowed.image.name} />
                    {mapShowed.objects.map((viewpoint) => {
                      const currentPoint = viewpoint.id === currentPano._id;
                      const [left, top] = viewpoint.coordinates;
                      const leftInPercent = left * 100;
                      const topInPercent = top * 100;
                      const currentScene =
                        scenes.find((s) => s._id === viewpoint.id) || {};

                      return (
                        <div
                          key={viewpoint.id}
                          className={`viewpoint-container ${
                            currentPoint ? 'current-viewpoint' : 'viewpoint'
                          }  ${
                            nightScene.includes(viewpoint.id)
                              ? 'night-point'
                              : ''
                          }`}
                          style={{
                            left: `${leftInPercent}%`,
                            top: `${topInPercent}%`,
                          }}
                          onClick={() => {
                            if (mobile.current) {
                              setIsActive(false);
                              setIsActiveMap(false);
                            }

                            if (aerial.includes(currentScene.id)) {
                              console.log(currentScene.id === aerial[0]);

                              dispatch(
                                setDateLight(currentScene.id.includes("daylight")? true : false)
                              );
                              setTimeout(() => {
                                onSelect('welcome', currentScene.id, true);
                              }, 0);
                              return;
                            }

                            onSelect(currentScene.groupId, currentScene.id);
                          }}
                        >
                          <p className="scene-title">{currentScene.title}</p>
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className="mapfloorplan_close"
                    onClick={() => setIsActiveMap(false)}
                  >
                    <button className="mapfloorplan_close-btn">&times;</button>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <div
          className={`icon`}
          onClick={() => {
            toggleRotate();
          }}
        >
          <AutoRotateIcon shouldRotate={shouldRotate} activeColor={color} />
        </div>
        {!isPhone && canFullscreen() && menuPosition !== 'bottom' && (
          <div className={`icon`} onClick={handleFullScreen}>
            <FullScreenIcon fullScreen={fullScreen} activeColor={color} />
          </div>
        )}
      </div>
    </>
  );
};

export default MenuActions;
