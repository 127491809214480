import React, { useEffect, useState, useMemo, useRef } from 'react';

import clientLogo from '../../assets/images/logo/logo-khaihoanprime.png';

import './style.scss';
import BackgroundLayer from './BackgroundLayer';
// import patternPng from './khp-pattern-sm.png';

const LoadingImage = (props) => {
  const { loaded, onClosing, onClosed } = props;
  const loadingImage = useMemo(() => clientLogo, []);
  const [enabled, setEnabled] = useState(true);
  const [enableAnimation, setEnabledAnimation] = useState(false);
  const startTimer = useRef(Date.now());
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (loaded) {
      (async () => {
        const elapsedTime = Date.now() - startTimer.current;

        if (elapsedTime < 4000) {
          await delay(4000);
        }
        setEnabledAnimation(true);
        await delay(3000);
        onClosing && onClosing();
        setEnabled(false);
        onClosed && onClosed();
        window.logMessage('Loading image closed');
      })();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const progress = useRef(0);
  const progressEl = useRef(null);

  useEffect(() => {
    const updateProgress = () => {
      const el = progressEl.current;
      if (el) {
        el.style.width = `${progress.current}%`;
      }
    };
    if (loaded) {
      setTimeout(() => {
        progress.current = 100;
        updateProgress();
      }, 1000);
      return;
    }
    const timer = setInterval(() => {
      progress.current += 1;
      updateProgress();
      if (progress.current >= 100) {
        clearInterval(timer);
      }
    }, 40);

    return () => clearInterval(timer);
  }, [progress, loaded]);

  return (
    <>
      {enabled ? (
        <div
          className={`loading-container ${enableAnimation ? 'fade-out' : ''}`}
        >
          <BackgroundLayer />
          <div className="loading-pattern">
            {/* <img src={patternPng} alt="Loading pattern" /> */}
          </div>
          <div className="loading-image">
            <img src={loadingImage} alt="Loading intro" />

            <div className="loading-bar">
              <p ref={progressEl} className="loading-progress">
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LoadingImage;
