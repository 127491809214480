export const sceneDayTime = {
  can_ho_1: 'khu-vuc-la-ban-1',
  aerial: 'aerial-daylight',
  amenitiesl_mall: 'amenitiesl-mall-daylight',
  amenitiesl_park: 'amenitiesl-park-daylight',
  amenitiesl_hongkong: 'amenitiesl-hongkong-daylight',
  // can_ho_2: 'can-ho-2',
  // can_ho_3: 'can-ho-3',
  // can_ho_4: 'can-ho-4',
  // can_ho_5: 'can-ho-5',
  // can_ho_6: 'can-ho-6',
};

export const sceneNightTime = {
  can_ho_1: 'khu-vuc-la-ban-1---ban-dem',
  aerial: 'aerial-night',
  amenitiesl_mall: 'amenitiesl-mall-night',
  amenitiesl_park: 'amenitiesl-park-night',
  amenitiesl_hongkong: 'amenitiesl-hongkong-night',
  // can_ho_2: 'can-ho-2---ban-dem',
  // can_ho_3: 'can-ho-3---ban-dem',
  // can_ho_4: 'can-ho-4---ban-dem',
  // can_ho_5: 'can-ho-5---ban-dem',
  // can_ho_6: 'can-ho-6---ban-dem',
};

export const sceneDay2Night = {
  [sceneDayTime.can_ho_1]: sceneNightTime.can_ho_1,
  [sceneDayTime.aerial]: sceneNightTime.aerial,
  [sceneDayTime.amenitiesl_mall]: sceneNightTime.amenitiesl_mall,
  [sceneDayTime.amenitiesl_park]: sceneNightTime.amenitiesl_park,
  [sceneDayTime.amenitiesl_hongkong]: sceneNightTime.amenitiesl_hongkong,
  // [sceneDayTime.can_ho_2]: sceneNightTime.can_ho_2,
  // [sceneDayTime.can_ho_3]: sceneNightTime.can_ho_3,
  // [sceneDayTime.can_ho_4]: sceneNightTime.can_ho_4,
  // [sceneDayTime.can_ho_5]: sceneNightTime.can_ho_5,
  // [sceneDayTime.can_ho_6]: sceneNightTime.can_ho_6,
};

export const sceneNight2Day = {
  [sceneNightTime.can_ho_1]: sceneDayTime.can_ho_1,
  [sceneNightTime.aerial]: sceneDayTime.aerial,
  [sceneNightTime.amenitiesl_mall]: sceneDayTime.amenitiesl_mall,
  [sceneNightTime.amenitiesl_park]: sceneDayTime.amenitiesl_park,
  [sceneNightTime.amenitiesl_hongkong]: sceneDayTime.amenitiesl_hongkong,
  // [sceneNightTime.can_ho_2]: sceneDayTime.can_ho_2,
  // [sceneNightTime.can_ho_3]: sceneDayTime.can_ho_3,
  // [sceneNightTime.can_ho_4]: sceneDayTime.can_ho_4,
  // [sceneNightTime.can_ho_5]: sceneDayTime.can_ho_5,
  // [sceneNightTime.can_ho_6]: sceneDayTime.can_ho_6,
};

const objHasValue = (obj, val) => {
  return Object.values(obj).indexOf(val) !== -1;
};

export const isDayScene = (sceneId) => {
  return objHasValue(sceneDayTime, sceneId);
};

export const isNightScene = (sceneId) => {
  return objHasValue(sceneNightTime, sceneId);
};

export const getOppositeScene = (sceneId) => {
  console.log('sceneId', sceneId, isDayScene(sceneId), isNightScene(sceneId));
  if (isDayScene(sceneId)) {
    return sceneDay2Night[sceneId];
  } else if (isNightScene(sceneId)) {
    return sceneNight2Day[sceneId];
  } else {
    return null;
  }
};

export const sceneDayNightList = [
  ...Object.values(sceneDayTime),
  ...Object.values(sceneNightTime),
];

export const isDayOrNight = (sceneId) => {
  return sceneDayNightList.indexOf(sceneId) !== -1;
};
