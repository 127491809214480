import React from 'react';
import { IconBack } from './icons/IconBack';
import { useDispatch, useSelector } from 'react-redux';
import { goToScene } from 'store/actions';

const BackButton = () => {
  const dispatch = useDispatch();
  const previousScene = useSelector((state) => state.previousScene);

  const _handleGoBack = () => {
    const scene = previousScene[previousScene.length - 2];
    dispatch(goToScene(scene.groupId, scene.id, true));
  };

  if (previousScene.length < 2) 
    return null;
  
  return  <div id="back-button" onClick={_handleGoBack}>
      <IconBack />
    <span>Quay lại</span>
  </div>
};

export default BackButton;
