import React from 'react';
import { IconFacebook } from './icons/IconFacebook';
import './style.scss';
import { IconHome } from './icons/IconHome';
import { IconYoutube } from './icons/IconYoutube';
import { useMediaQuery } from 'react-responsive';
const MenuSocial = () => {
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' });

  if (isPhone) return null;
  return (
    <div id="menu-social">
      <div className="container">
        <a
          href="https://thelarita.vn/"
          target="_blank"
          className="social-item"
          rel="noreferrer"
          onClick={(e) => e.preventDefault()}
        >
          <IconHome />
        </a>
        <a
          href="https://www.facebook.com/duanthelarita/"
          target="_blank"
          className="social-item"
          rel="noreferrer"
          onClick={(e) => e.preventDefault()}
        >
          <IconFacebook />
        </a>
        <a
          href="https://www.youtube.com/channel/UCp9A1XJV5wd_HzkdRGwPRGQ"
          target="_blank"
          className="social-item"
          rel="noreferrer"
          onClick={(e) => e.preventDefault()}
        >
          <IconYoutube />
        </a>
      </div>
    </div>
  );
};

export default MenuSocial;
