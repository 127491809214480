import { useThree } from '@react-three/fiber';
import configs from 'configs';
import {
  DEFAULT_ROTATION_X_ANGLE,
  STEP_WIDTH,
  TOP_Y,
  TRANSFORM_RATIO,
} from 'containers/world/constant';
import { useEffect, useRef } from 'react';
import { FrontSide, TextureLoader } from 'three';
import * as THREE from 'three';
import { degToRad } from 'three/src/math/MathUtils';

const JumpSpotArrowAnimation = ({
  location,
  rotate = 0,
  fileName = '',
  scale = 1,
  onClick = () => {},
}) => {
  const meshRef = useRef();
  const { camera } = useThree();

  const textureRef = useRef();

  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load(
      configs.baseUrl + `/assets/images/navigation/${fileName}`,
      (texture) => {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        textureRef.current = texture;
        if (meshRef.current?.material) {
          meshRef.current.material.map = texture;
          meshRef.current.material.needsUpdate = true;
        }
      }
    );
    // eslint-disable-next-line
  }, [meshRef]);

  useEffect(() => {
    const [x, y, z] = location;
    // const { scaleX, scaleY } = calcScaleForProjection(y);
    // const angleRotate = calcFootRotationY(location);
    meshRef.current.visible = y <= TOP_Y;
    meshRef.current.position.set(
      TRANSFORM_RATIO * x,
      TRANSFORM_RATIO * y,
      TRANSFORM_RATIO * z
    );
    // meshRef.current.scale.set(scaleX, scaleY);
    // scaleRef.current.factor = 1;
    // scaleRef.current.x = scaleX;
    // scaleRef.current.y = scaleY;
    meshRef.current.rotation.set(
      DEFAULT_ROTATION_X_ANGLE,
      0,
      degToRad(0 + rotate)
    );
    // eslint-disable-next-line
  }, [location, camera.position]);

  return (
    <mesh
      ref={meshRef}
      onClick={onClick}
      onPointerOver={() => (document.body.style.cursor = 'pointer')}
      onPointerOut={() => (document.body.style.cursor = 'default')}
    >
      <planeGeometry args={[STEP_WIDTH * scale, (STEP_WIDTH / 1.5) * scale]} />
      <meshPhongMaterial
        color={'white'}
        alphaTest={0.5}
        side={FrontSide}
        transparent
        opacity={1.5}
      />
    </mesh>
  );
};

export default JumpSpotArrowAnimation;
