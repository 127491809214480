import React from 'react';
import Compass from 'components/Compass';

export default function LaritaLayout({
  currentPano,
  cameraParams,
  sceneRotation,
}) {
  return (
    <>
      <Compass
        currentPano={currentPano}
        cameraParams={cameraParams}
        sceneRotation={sceneRotation}
      />
    </>
  );
}
