import React, { useCallback, useMemo } from 'react';
import './mediaHotspotLine.scss';

const MediaHotspot = ({
  media,
  height,
  goToScene,
  handleApartmentPopup,
  currentPano,
}) => {
  const contents = useMemo(() => {
    const res = [];
    const title = media.title || '';
    if (title && title !== '.') {
      res.push(title);
    }
    const description = (media.infoTag?.text || '').split('\n');
    res.push(...description);
    return res;
  }, [media]);

  const imgUrl = useMemo(() => {
    if (media.type === 'Image' || media.previewUrl) {
      return media.imageURL || media.previewUrl;
    }
    return null;
  }, [media]);

  const isSalesGallery = useMemo(() => media.id === 'sales-gallery', [media]);

  const isAerialProjectGate = useMemo(
    () => currentPano.id === 'aerial-daylight' && media.id === 'project-gate',
    [currentPano, media]
  );

  const onHover = () => {};

  const handleClick = useCallback(() => {
    if (isSalesGallery) {
      // goToScene('can-ho-mau', '2');
      handleApartmentPopup();
    }
  }, [isSalesGallery, handleApartmentPopup]);

  return (
    <div
      className={`khp-media-hotspot ${isSalesGallery ? 'big-bottom' : ''}`}
      onMouseEnter={onHover}
      onClick={handleClick}
    >
      <div
        className={`khp-media-hotspot__container ${
          isAerialProjectGate ? 'project-gate' : ''
        }`}
        style={{ height: `${height * 50}px` }}
      >
        <div
          className={`khp-media-hotspot__title ${
            media?.offSiteAmenities ? 'offsite' : ''
          } ${media?.road ? 'road' : ''} ${
            isAerialProjectGate ? 'project-gate' : ''
          }`}
        >
          {contents.map((txt, index) => (
            <p key={index} className={contents.length > 2 ? 'text-left' : ''}>
              {txt}
            </p>
          ))}
          {imgUrl && (
            <img
              className="khp-media-hotspot__preview"
              src={imgUrl}
              alt={media.title}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaHotspot;
