import React, { useEffect, useState, useMemo } from 'react';
import './FloorButtons.scss';

export default function FloorButtons({
  onSelect,
  currentPano,
  isMobile = false,
}) {
  const [activeFloor, setActiveFloor] = useState(isMobile ? 1 : 'Tầng trệt');

  const floorMappings = useMemo(
    () => ({
      'Tầng trệt': {
        panoIds: ['can-ho-8', 'can-ho-9'],
        onSelectParams: ['welcome', 'can-ho-8'],
      },
      'Tầng lửng': {
        panoIds: ['can-ho-1', 'can-ho-2'],
        onSelectParams: ['can-ho-mau', 'can-ho-1'],
      },
      'Tầng 2': {
        panoIds: ['can-ho-5', 'can-ho-3', 'can-ho-4'],
        onSelectParams: ['welcome', 'can-ho-3'],
      },
      'Tầng 3': {
        panoIds: ['can-ho-6', 'can-ho-7', 'can-ho-10', 'can-ho-11'],
        onSelectParams: ['welcome', 'can-ho-6'],
      },
    }),
    []
  );

  const handleButtonClick = (floor) => {
    setActiveFloor(floor);
    const selectedFloor = floorMappings[floor]?.onSelectParams;
    if (selectedFloor) {
      onSelect(...selectedFloor);
    }
  };

  useEffect(() => {
    const selectedFloor = Object.keys(floorMappings).find((floor) =>
      floorMappings[floor].panoIds?.includes(currentPano?.id)
    );
    if (selectedFloor) {
      setActiveFloor(selectedFloor);
    }
  }, [currentPano, floorMappings]);

  return (
    <div className={isMobile ? 'mobile-floor--buttons' : 'floor-buttons'}>
      {isMobile && <span className="mobile-floor--buttons-btn">TẦNG:</span>}
      {Object.keys(floorMappings).map((floor, index) => (
        <span
          key={index}
          className={`${
            isMobile ? 'mobile-floor--buttons-btn' : 'floor-buttons--btn'
          } ${activeFloor === floor ? 'active' : ''}`}
          onClick={() => handleButtonClick(floor)}
        >
          {isMobile ? floor.replace('Tầng ', '') : floor}
        </span>
      ))}
    </div>
  );
}
