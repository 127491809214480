import React, { useMemo } from 'react';
import './sceneOutdoorHotspot.scss';
import './mediaHotspotLine.scss';

const SceneOutdoorHotspot = ({
  scene,
  onClick,
  media,
  height,
  goToScene,
  handleApartmentPopup,
}) => {
  // eslint-disable-next-line
  const titles = useMemo(() => {
    if (scene.title) {
      const t = (scene.title || '').toLowerCase();
      if (t.toLowerCase().indexOf('sân tennis') === 0) {
        return t
          .split('&')
          .map((s) => s.replace('- ban đêm', '').replace('ban đêm', '').trim());
      }
      return [t].map((s) =>
        s.replace('- ban đêm', '').replace('ban đêm', '').trim()
      );
    }
    return [''];
  }, [scene]);

  return (
    <div
      className={`khp-media-hotspot thelarita-scene--cover-spot`}
      // onMouseEnter={onHover}
      onClick={onClick}
    >
      <div
        className="khp-media-hotspot__container"
        style={{ height: `${50 * height}px` }}
      >
        <div
          className="khp-media-hotspot__title thelarita-scene--title"
          onClick={onClick}
        >
          {/* {contents.map((txt, index) => (
            <p key={index} className={contents.length > 2 ? 'text-left' : ''}>
              {txt}
            </p>
          ))}
          {imgUrl && (
            <img
              className="khp-media-hotspot__preview"
              src={imgUrl}
              alt={media.title}
            />
          )} */}
          <p className="thelarita-scene--paragraph">{scene.title}</p>

          <div className="thelarita-scene--preview">
            <img src={scene.previewImgUrl} alt="scene-preview" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SceneOutdoorHotspot;
