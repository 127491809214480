import React, { useState } from 'react';
import CheckingSwitchDayTime from 'components/DayTimeSwitch/CheckingSwitchDayTime';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { IconHamburger } from './icons/IconHamburger';
import { IconHome } from './icons/IconHome';
import { IconFacebook } from './icons/IconFacebook';
import { IconYoutube } from './icons/IconYoutube';
import { ArrowUp } from 'components/icons';
import FloorButtons from './FloorButtons';
import BackButton from './BackButton';

const MenuBottom = ({
  currentPano,
  onSelect = () => {},
  menuActions,
  isActive,
  setIsActive,
  isRenderFloorButtons,
}) => {
  const isPhone = useMediaQuery({ query: '(max-width: 767px)' });
  const logoURL = useSelector((state) => state.logoURL);

  const [subMenuFocus, setSubMenuFocus] = useState(null);

  const menuItems = [
    {
      label: 'TỔNG QUAN',
      key: 'overview',
      scene: 'aerial-daylight',
      group: 'welcome',
    },
    {
      label: 'TIỆN ÍCH',
      key: 'amenities',
      subMenu: [
        {
          label: 'Trung Tâm Thương Mại',
          key: 'amenitiesl-mall',
          imageUrl:
            '/assets/project-images/media/menu-images/trung-tam-thuong-mai.jpg',
          scene: 'amenitiesl-mall-daylight',
          group: 'welcome',
        },
        {
          label: 'Thương Phố Hongkong',
          key: 'amenitiesl-hongkong',
          imageUrl:
            '/assets/project-images/media/menu-images/thuong-pho-hong-kong.jpg',
          scene: 'amenitiesl-hongkong-daylight',
          group: 'welcome',
        },

        {
          label: 'Tổ Hợp Hồ Bơi Muối Khoáng Clubhouse',
          key: 'ho-boi-night',
          imageUrl: '/assets/project-images/media/menu-images/ho-boi.jpg',
          scene: 'ho-boi-night',
          group: 'can-ho-mau',
        },

        {
          label: 'Khu Thể Thao Ngoài Trời',
          key: 'amenitiesl-park',
          imageUrl: '/assets/project-images/media/menu-images/san-the-thao.jpg',
          scene: 'amenitiesl-park-daylight',
          group: 'welcome',
        },

        {
          label: 'Vườn Hái Lộc',
          key: 'vuon-hai-loc-daylight',
          imageUrl: '/assets/project-images/media/menu-images/vuon-hai-loc.jpg',
          scene: 'vuon-hai-loc-daylight',
          group: 'can-ho-mau',
        },
      ],
    },
    {
      label: 'NHÀ MẪU',
      key: 'model-house',
      scene: 'can-ho-8',
      group: 'welcome',
    },
  ];

  const _handleSelect = (group, sId) => {
    onSelect(group, sId);
    isPhone && isActive && setIsActive(false);
    isPhone && !!subMenuFocus && setSubMenuFocus(null);
  };

  const _handleClickMenu = (item) => {
    if (!!item.scene) {
      _handleSelect(item.group, item.scene);
      return;
    }
    if (!!item.subMenu) {
      setSubMenuFocus((prev) => {
        if (prev === item.key) return null;
        return item.key;
      });
    }
  };

  const _renderMenuList = () => {
    return menuItems.map((item) => {
      const isActiveMenu = item.key === subMenuFocus;
      const finalSubMenu = item.subMenu;

      return (
        <div
          key={item.key}
          className={'item'}
          onClick={() => _handleClickMenu(item)}
        >
          <div className={`item-label-wrapper ${isActiveMenu ? 'active' : ''}`}>
            <span className={`item-label`}>{item.label}</span>
            {isPhone && !!finalSubMenu && <ArrowUp />}
          </div>
          {!!finalSubMenu && (
            <ul
              className={`sub-menu ${isPhone ? 'mobile' : ''} ${
                isActiveMenu ? 'expanded' : ''
              }`}
            >
              {finalSubMenu.map((sub) => {
                return (
                  <li
                    key={sub.key}
                    className="sub-item"
                    onClick={() => _handleClickMenu(sub)}
                  >
                    {sub.label}
                    {!isPhone && (
                      <img
                        className="img-preview"
                        src={sub.imageUrl}
                        alt="preview"
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div id="bottom-menu-v2">
        {isPhone && !isActive && (
          <div className={`tour-logo`}>
            <img alt="tour-logo" src={logoURL}></img>
          </div>
        )}

        {isPhone ? (
          <div className="" onClick={() => setIsActive((prev) => !prev)}>
            <IconHamburger isActive={isActive} />
          </div>
        ) : (
          <div className="menu--items">
            <a
              href="https://thelarita.vn/"
              target="_blank"
              rel="noreferrer"
              className="logo-tour"
              onClick={(e) => e.preventDefault()}
            >
              <img src="/assets/images/logo/logo_larita.png" alt="logo" />
            </a>
            {_renderMenuList()}
          </div>
        )}

        <div className="menu--actions">
          {isPhone && isRenderFloorButtons && (
            <FloorButtons
              currentPano={currentPano}
              onSelect={onSelect}
              isMobile={true}
            />
          )}
          <CheckingSwitchDayTime
            currentPano={currentPano}
            onSelect={onSelect}
            // openMenu={openMenu}
          />

          <div className="action">{menuActions({ isHiddenMap: isActive })}</div>
          <BackButton />
        </div>
      </div>

      {isPhone && (
        <div id="bottom-menu-v2__mobile" className={isActive ? 'visible' : ''}>
          <div className="mobile-menu-wrapper">
            <div className="menu-header">
              <img alt="tour-logo" src={logoURL}></img>
            </div>
            <div className="menu-items">
              {_renderMenuList()}
              <div className="social-icons">
                <div className="container">
                  <a
                    href="https://thelarita.vn/"
                    target="_blank"
                    className="social-item"
                    rel="noreferrer"
                    onClick={(e) => e.preventDefault()}
                  >
                    <IconHome />
                  </a>
                  <a
                    href="https://www.facebook.com/duanthelarita/"
                    target="_blank"
                    className="social-item"
                    rel="noreferrer"
                    onClick={(e) => e.preventDefault()}
                  >
                    <IconFacebook />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCp9A1XJV5wd_HzkdRGwPRGQ"
                    target="_blank"
                    className="social-item"
                    rel="noreferrer"
                    onClick={(e) => e.preventDefault()}
                  >
                    <IconYoutube />
                  </a>
                </div>
              </div>
              <a
                className="back-to-home"
                href="https://thelarita.vn/"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.preventDefault()}
              >
                Trở về trang chủ
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuBottom;
