export const IconHamburger = ({ isActive }) => {
  return (
    <div
      id="hamburger-1"
      className={`hamburger ${isActive ? 'is-active' : ''}`}
    >
      <span className="line" />
      <span className="line" />
      <span className="line" />
    </div>
  );
};
