import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { isMobile } from 'react-device-detect';
import CalloutLine from './calloutLine';
import { Html } from './Html';
import { Html as ThreeHtml } from '@react-three/drei';
import CanvasCircle from './canvasCircle';
import Canvas from './canvas';
import { CANVAS_W, CANVAS_H } from './utils';
import HotspotContainer from './hotspotContainer';
import HotspotIcon from '../icons';
import MEDIA_TYPES from 'consts/mediaType.const';
import {
  get2DScreenPosition,
  distanceBetween2dCoordinates,
} from 'utils/positionHelper';
import { getCenterScreen } from 'containers/world/utils';
import { trackTourContentVisible } from 'gaTracking';
import { useSelector } from 'react-redux';
import { HOTSPOT_UI, LAYOUT_UI } from 'consts/ui.const';
import { checkIsShowHotspotMap } from 'utils';
import CanvasPulsePoint from './canvasPulsePoint';
import AvoriazHotspot from 'components/AvoriazLayout/AvoriazHotspot';
import ClearSpaceHotspot from 'containers/world/sceneController/ClearSpaceHotspot/ClearSpaceHotspot';
import JumpSpotArrowAnimation from 'containers/world/sceneController/ClearSpaceHotspot/JumpSpotArrowAnimation';

import MediaHotspot from './mediaHotspotLine';
import SceneOutdoorHotspot from './sceneOutdoorHotspot';

import './lineSingle.scss';
import SceneFlycamOutdoorHotspot from './sceneFlycamOutdoorHotspot';
import { useDispatch } from 'react-redux';

const MAX_VISIBLE_DISTANCE = 150;
const HOVERzINDEX = '100';
const zIndexRange1 = [70, 0];
const zIndexRangeHover = [100, 100];

const styles = {
  width: `${CANVAS_W}px`,
  height: `${CANVAS_H}px`,
  pointerEvents: 'none',
};

const MEDIA_DATA = {
  'khu-vuc-la-ban-1': {
    'truong-th-': 1.2,
  },

  'aerial-daylight': {
    'media-ttbenhluc': 2.3,
    'media-kcnphuclong': 1.7,
    'media-kcnvinhloc2': 2.3,
    'media-ubndbinhchanh': 2.9,
    'media-chobinhchanh': 2.2,
    'media-thbinhchanh': 1.6,
    'media-benhviennhidong': 2.4,
    'media-daihocykhoa': 3,
    'media-benhvientruyenmau': 1.8,
    'media-phumyhung': 2.4,
    'media-chobinhdien': 1.7,
    'media-bbq-cinema': 2.2,
    'media-tranditional-game': 3.4,
    'amenitiesl-hongkong-daylight': 6,
    'media-clubhouse': 1.9,
    'amenitiesl-mall-daylight': 5.1,
    'amenitiesl-park-daylight': 3.5,
    'media-cultural-house': 2,
    'media-checkin-icon': 2.8,
    'media-theme-park': 4,
    'theme-park': 2.5,
    'media-gym-yoga': 3.9,
    'media-outdoor-sport': 1.7,
    'media-chotanbuu': 2,
    'project-gate': 0.8,
    'media-thcstantuc': 2.5,
    'media-thpttantuc': 1.9,
  },

  'aerial-night': {
    'media-thcsnguyenvanhien': 1.7,
    'media-theme-park': 0.9,
    'media-tranditional-game': 2.3,
    'media-bbq-cinema': 1,
    'amenitiesl-mall-night': 5,
    'media-outdoor-sport': 2,
    'amenitiesl-park-night': 4,
    'media-checkin-icon': 3.2,
    'media-gym-yoga': 4,
    'media-clubhouse': 1.9,
    'amenitiesl-hongkong-night': 1.7,
    'media-chotanbuu': 1.7,
    'media-benhviennhidong': 2.4,
    'media-daihocykhoa': 3,
    'media-benhvientruyenmau': 1.8,
    'media-chobinhdien': 2.2,
    'media-phumyhung': 2.9,
    'media-kcnvinhloc2': 2.3,
    'media-ubndbinhchanh': 2.9,
    'media-chobinhchanh': 2.2,
    'media-thbinhchanh': 1.6,
    "media-kcnphuclong": 1.5
  },

  'amenitiesl-park-daylight': {
    'amenitiesl-hongkong-daylight': 2.5,
    'media-tranditional-game': 4.8,
    'media-bbq-cinema': 6,
    'media-chess-zone': 3.9,
    'media-outdoor-sport': 3.3,
    'media-gym-yoga': 3,
    'media-checkin-icon': 2,
    'media-sport-field': 1.1,
  },

  'amenitiesl-park-night': {
    'amenitiesl-mall-night': 5,
    'amenitiesl-hongkong-night': 2.5,
    'media-tranditional-game': 3.9,
    'media-bbq-cinema': 5.8,
    'media-chess-zone': 4.9,
    'media-outdoor-sport': 3.5,
    'media-gym-yoga': 3,
    'media-checkin-icon': 2,
  },

  'amenitiesl-hongkong-daylight': {
    'media-bbq-cinema': 1.5,
    'media-cultural-house': 1.7,
    'media-vanhdai3': 2.2,
    'media-mineral-salt-pool': 1.7,
    'media-kid-garden': 2,
    'project-gate': 1.5,
    'media-gate': 1.6,
  },

  'amenitiesl-hongkong-night': {
    'media-bbq-cinema': 1,
    'media-cultural-house': 1.7,
    'media-kid-garden': 2,
    'media-vanhdai3': 2.2,
    'media-mineral-salt-pool': 1.7,
    'media-gate': 1.5,
    'project-gate': 1.6,
  },

  'amenitiesl-mall-daylight': {
    'media-cultural-house': 1.7,
    'amenitiesl-park-daylight': 1.8,
    'media-gate': 1.9,
    'media-tranditional-game': 3.1,
    'vuon-hai-loc-daylight': 1.8,
  },

  'amenitiesl-mall-night': {
    'media-bbq-cinema': 2,
    'media-clubhouse': 1.7,
    'media-cultural-house': 1.7,
    'amenitiesl-park-night': 1.8,
    'media-gate': 1.9,
    'media-tranditional-game': 2.8,
  },

  'vuon-hai-loc-daylight': {
    'media-kid-garden': 2,
  },
};

const ZINDEXRANGEDATA = {
  'aerial-night': [
    'amenitiesl-mall-night',
    'media-gym-yoga',
    'media-checkin-icon',
    'media-health-care-center',
  ],
  'amenitiesl-hongkong-daylight': ['media-kid-garden'],
  'amenitiesl-hongkong-night': ['media-kid-garden'],
  'amenitiesl-park-daylight': ['media-bbq-cinema', 'media-tranditional-game'],
  'amenitiesl-park-night': [
    'media-bbq-cinema',
    'media-outdoor-sport',
    'media-tranditional-game',
    'media-chess-zone',
  ],
  'amenitiesl-mall-daylight': ['media-tranditional-game', 'media-chess-zone'],
};

const LineSingle = (props) => {
  const { media, hotspot, currentPano, onSelect } = props;
  const { camera } = useThree();
  const canvasRef = useRef();
  const hotspotRef = useRef();
  const elRef = useRef();
  const [zIndex, setZIndex] = useState('0');

  const initZIndex = useMemo(
    () =>
      ZINDEXRANGEDATA[currentPano?.id]?.includes(media?.id) ? [50, 0] : [70, 0],

    [media, currentPano]
  );

  const [zIndexRange, setZIndexRange] = useState(initZIndex);
  const [hovering, setHovering] = useState(false);
  const trackingRef = useRef(false);
  const params = useSelector(({ searchParams }) => searchParams);
  const hotspotStyle = useSelector(({ tour }) => tour.hotspotStyle);
  const isAquaStyle = useSelector(({ tour }) => tour.menuStyle === 'aqua');
  const isAvoriazStyle = useSelector(
    ({ tour }) => tour.menuStyle === LAYOUT_UI.AVORIAZ
  );
  const isClearSpaceStyle = useSelector(
    ({ tour }) => tour.menuStyle === LAYOUT_UI.CLEAR_SPACE
  );

  const dispatch = useDispatch();

  const isCalloutStyle = useSelector(
    ({ searchParams }) => searchParams['hotspot-ui'] === HOTSPOT_UI.CALLOUT
  );
  const isTag = useMemo(() => {
    return media?.type === MEDIA_TYPES.INFO_TAG;
  }, [media]);

  const hoverToggled = (isHover) => {
    const parentNode = elRef.current && elRef.current.parentNode;
    setHovering(Boolean(isHover));
    if (isHover) {
      setZIndexRange(zIndexRangeHover);
      props.setHotspotHovering(true);
      if (parentNode) {
        setZIndex(parentNode.style.zIndex);
        parentNode.style.zIndex = HOVERzINDEX;
      }
    } else {
      props.setHotspotHovering(false);
      setTimeout(() => {
        if (elRef && elRef.current) {
          setZIndexRange(zIndexRange1);
          if (parentNode) {
            parentNode.style.zIndex = zIndex;
          }
        }
      }, 0);
    }
  };

  useFrame(() => {
    if (isMobile) {
      const [x, y, z] = props.position;
      const xyHotSpotPos = get2DScreenPosition(
        new THREE.Vector3(x, y, z),
        camera,
        window.innerWidth,
        window.innerHeight
      );
      const screenCenter = getCenterScreen();
      const distance = distanceBetween2dCoordinates(xyHotSpotPos, screenCenter);
      if (distance <= MAX_VISIBLE_DISTANCE) {
        setHovering(true);
      } else {
        setHovering(false);
      }
    }
  });

  const onHotspotVisible = useCallback(() => {
    if (props.media && !trackingRef.current) {
      trackTourContentVisible({
        media_id: props.media._id,
        tour_id: props.tour.id,
        scene_id: props.currentPano._id,
      });

      trackingRef.current = true;
    }
  }, [props.media, props.currentPano._id, props.tour.id]);

  const isShowMapFloorPlanButton = useMemo(() => {
    return checkIsShowHotspotMap(hotspot);
  }, [hotspot]);

  const configsData = useMemo(() => {
    if (!props?.hotspot?.configs) return null;
    return JSON.parse(props?.hotspot?.configs);
  }, [props?.hotspot?.configs]);

  const checkMediaLineHeight = (currentPano, hotspot) => {
    const heights = MEDIA_DATA[currentPano.id]?.[hotspot.id];

    if (heights) {
      return heights;
    }
    return 1;
  };

  const mediaHeight = useMemo(() => {
    // if (!media) return 0;
    return checkMediaLineHeight(currentPano, hotspot, media);
  }, [currentPano, hotspot, media]);

  if (media) {
    return (
      <Html
        ref={elRef}
        position={props.position}
        center={true}
        zIndexRange={zIndexRange}
        // style={styles}
        onVisible={onHotspotVisible}
        className="hotspot-khp-container"
      >
        <MediaHotspot
          media={media}
          height={mediaHeight}
          goToScene={props.goToScene}
          handleApartmentPopup={props.handleApartmentPopup}
          currentPano={props.currentPano}
        />
      </Html>
    );
  }

  if (hotspot && hotspot.flycamOutdoorScene) {
    return (
      <Html
        ref={elRef}
        position={props.position}
        center={true}
        zIndexRange={zIndexRange}
        // style={styles}
        onVisible={onHotspotVisible}
        className="hotspot-khp-container"
      >
        <SceneFlycamOutdoorHotspot
          currentPano={currentPano}
          scene={props.scene}
          onClick={props.onClick}
          media={media}
          height={mediaHeight}
          goToScene={props.goToScene}
          handleApartmentPopup={props.handleApartmentPopup}
          dispatch={dispatch}
          onSelect={onSelect}
        />
      </Html>
    );
  }

  if (hotspot && hotspot.outdoorScene) {
    return (
      <Html
        ref={elRef}
        position={props.position}
        center={true}
        zIndexRange={zIndexRange}
        // style={styles}
        onVisible={onHotspotVisible}
        className="hotspot-khp-container"
      >
        <SceneOutdoorHotspot
          scene={props.scene}
          onClick={props.onClick}
          media={media}
          height={mediaHeight}
          goToScene={props.goToScene}
          handleApartmentPopup={props.handleApartmentPopup}
        />
      </Html>
    );
  }

  if (isClearSpaceStyle)
    return (
      <ThreeHtml ref={elRef} position={props.position}>
        <ClearSpaceHotspot
          hotspot={props.hotspot}
          groups={props.groups}
          onClick={props.onClick}
        />
      </ThreeHtml>
    );

  if (isAvoriazStyle) {
    return (
      <ThreeHtml ref={elRef} position={props.position}>
        <AvoriazHotspot
          hotspot={props.hotspot}
          groups={props.groups}
          onClick={props.onClick}
        />
      </ThreeHtml>
    );
  }

  if (
    hotspotStyle === HOTSPOT_UI.CALLOUT ||
    isTag ||
    isCalloutStyle ||
    isAquaStyle
  ) {
    return (
      <CalloutLine
        ref={elRef}
        hotspotRef={hotspotRef}
        canvasRef={canvasRef}
        position={props.position}
        center={true}
        zIndexRange={zIndexRange}
        style={styles}
        onVisible={onHotspotVisible}
        forceUp={props.forceUp}
        isAquaStyle={isAquaStyle}
      >
        <>
          {isAquaStyle ? (
            <>
              <Canvas ref={canvasRef} />
              {configsData &&
              configsData.baseIconType === 'circle_animation' ? (
                <CanvasPulsePoint />
              ) : (
                <CanvasCircle />
              )}
            </>
          ) : (
            <>
              <CanvasCircle />
              <Canvas ref={canvasRef} />
            </>
          )}

          <HotspotContainer ref={hotspotRef}>
            <HotspotIcon
              type={props.type}
              hotspot={props.hotspot}
              currentSceneCoords={props.currentSceneCoords}
              scene={props.scene}
              media={props.media}
              tour={props.tour}
              onClick={props.onClick}
              isHover={hovering}
              toggleHover={(val) => hoverToggled(val)}
              hoverIn={() => hoverToggled(true)}
              hoverOut={() => hoverToggled(false)}
              visited={props.visited}
              color={props.color || ''}
              scope={props.scope}
              hotspotStyle={hotspotStyle}
              isShowMapFloorPlanButton={isShowMapFloorPlanButton}
            />
          </HotspotContainer>
        </>
      </CalloutLine>
    );
  }

  const isJumpSpot = props.hotspot?.typeHotspot === 'JumpSpot';

  if (isJumpSpot)
    return (
      <JumpSpotArrowAnimation {...props.hotspot} onClick={props.onClick} />
    );

  return (
    <Html
      ref={elRef}
      position={props.position}
      center={true}
      zIndexRange={zIndexRange}
      style={styles}
      onVisible={onHotspotVisible}
    >
      <HotspotContainer>
        <HotspotIcon
          type={props.type}
          hotspot={props.hotspot}
          currentSceneCoords={props.currentSceneCoords}
          scene={props.scene}
          media={props.media}
          tour={props.tour}
          onClick={props.onClick}
          isHover={hovering}
          toggleHover={(val) => hoverToggled(val)}
          hoverIn={() => hoverToggled(true)}
          hoverOut={() => hoverToggled(false)}
          visited={props.visited}
          color={props.color || ''}
          scope={props.scope}
          params={params}
          hotspotStyle={hotspotStyle}
          isShowMapFloorPlanButton={isShowMapFloorPlanButton}
        />
      </HotspotContainer>
    </Html>
  );
};

export default React.memo(LineSingle);
